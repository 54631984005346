<template>
  <v-dialog v-model="dialog" fullscreen @click:outside="clickOutside">
    <template v-slot:activator="{ on, attrs }">
      <p id="employeeImport" v-bind="attrs" v-on="on"></p>
    </template>
    <div style="overflow:hidden;">
      <v-form
        ref="entryForm"
        @submit.prevent="submit"
        style="position: relative; background:white;min-height:100vh;"
      >
        <v-card
          flat
          style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
        >
          <div
            style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 10px;
                "
          >
            <v-toolbar-title class="overline">
              <p
                v-if="wWidth > 780"
                style="font-size: 20px; margin: 0; font-weight: bold"
              >
                Import Data
              </p>
              <p v-else style="font-size: 14px; margin: 0; font-weight: bold">
                Import Data
              </p>
            </v-toolbar-title>
          </div>
          <div>
            <v-btn
              small
              outlined
              color="red"
              class="ma-2"
              style="color: white"
              @click="close"
            >
              <v-icon small class="me-2">mdi-close</v-icon>
              Tutup
            </v-btn>
          </div>
        </v-card>

        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:10px 15px 20px 15px; width:99%; margin-top:30px;"
        >
          <v-row>
            <v-col cols="12">
              <v-toolbar flat style="border-radius:10px 10px 0 0;">
                <v-btn class="mx-2 elevation-4" dark small color="cyan">
                  <v-icon small dark>
                    mdi-upload
                  </v-icon>
                </v-btn>
                <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                <v-toolbar-title>Upload File</v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12" style="padding:0;">
              <p style="margin:0; font-size:14px; padding-left:35px;">
                Upload file dari hasil eksport mesin thermal disini, pastikan
                format file harus .xlsx
              </p>
            </v-col>
            <v-col cols="12" style="padding-left:35px;">
              <!-- <p style="border-top:5px solid grey;"></p> -->
            </v-col>
            <v-col cols="12" style="padding: 0 0 30px 30px;">
              <v-row>
                <v-col cols="8" md="10">
                  <v-file-input
                    show-size
                    label="File input"
                    v-model="uploadFile"
                  />
                </v-col>
                <v-col cols="2">
                  <!-- a -->
                  <v-btn
                    small
                    color="primary"
                    class="ma-2 white--text"
                    @click="employeeImport"
                    :disabled="uploadFile === null"
                    :loading="loading"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:20px 10px; width:99%; margin-top:20px;"
        >
          <v-row>
            <v-col cols="12">
              <div style=" overflow:auto;">
                <v-data-table
                  dense
                  mobile-breakpoint="0"
                  :headers="headers"
                  :items="importData"
                  class="elevation-1 mx-auto row-pointer"
                  style="margin-top: 20px; margin-bottom:20px;min-width:1200px; max-width:99%;"
                  :items-per-page="itemsPerPage"
                  :item-class="itemRowBackground"
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                      style="height:100px; border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;"
                    >
                      <v-btn class="mx-2 elevation-4" dark small color="cyan">
                        <v-icon small dark>
                          mdi-clipboard-list-outline
                        </v-icon>
                      </v-btn>
                      <v-toolbar-title>Tabel hasil upload</v-toolbar-title>
                    </v-toolbar>
                  </template>
                  <!-- <template v-slot:[`item.nik`]="{ item }">
                    <div
                      :style="
                        `font-size:12px; padding: 0; display: flex; flex-direction: row;`
                      "
                    >
                      {{ item.nik }}
                    </div>
                  </template> -->
                </v-data-table>
              </div>
              <p class="caption" style="margin:0;">
                Total Import Berhasil: {{ totalSuccess }}
              </p>
              <p class="caption" style="margin:0;">
                Total Import Gagal: {{ totalFailed }}
              </p>
              <v-col cols="12" style="padding:0 5px;">
                <v-btn
                  small
                  style="margin-right:20px;"
                  color="success"
                  height="30px"
                  width="100px"
                  :disabled="!isImport"
                  @click="saveVueImport"
                  :loading="loading"
                >
                  Submit Data
                </v-btn>
                <v-btn
                  small
                  style="margin-right:20px;"
                  color="pink"
                  height="30px"
                  width="100px"
                  class="white--text"
                  @click="remove"
                  :loading="loading"
                  :disabled="importData.length === 0"
                >
                  Remove
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </div>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import buildType from '../../../../services/buildType'
export default {
  name: 'EmployeeImport',
  props: ['param'],
  data() {
    return {
      wWidth: window.innerWidth,
      upload: buildType.apiURL('upload3'),
      esanqua: buildType.apiURL('esanqua'),
      attendance: buildType.apiURL('attendance'),
      dialog: false,
      form: null,
      loading: false,
      uploadFile: null,
      isImport: false,
      itemsPerPage: 10,
      type: 2,
      totalSuccess: 0,
      totalFailed: 0,
      importData: []
    }
  },
  computed: {
    headers() {
      if (this.type === 1) {
        const headerTypeOne = []
        const keys = Object.keys(this.importData[0])
        console.log(keys)
        for (let i = 0; i < keys.length; i++) {
          if (
            keys[i] === 'body_temperature' ||
            keys[i] === 'device_code' ||
            keys[i] === 'employee_code' ||
            keys[i] === 'employee_name' ||
            keys[i] === 'attendance_time'
          ) {
            headerTypeOne.push({
              text: keys[i],
              value: keys[i],
              align: 'left',
              sortable: false
            })
          }
        }
        return headerTypeOne
      } else {
        return [
          {
            text: 'NIK',
            value: 'nik',
            align: 'left',
            sortable: false
          },
          {
            text: 'Time',
            value: 'attendance_time',
            align: 'left',
            sortable: false
          },
          {
            text: 'Message',
            value: 'msg',
            align: 'left',
            sortable: false
          }
        ]
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clear() {
      this.uploadFile = null
      this.type = 2
      this.importData = []
      this.isImport = false
      this.totalSuccess = 0
      this.totalFailed = 0
      this.$emit('loadPage')
    },
    clickOutside() {
      this.clear()
    },
    close() {
      this.clear()
      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    remove() {
      this.type = 2
      this.importData = []
      this.isImport = false
      this.totalSuccess = 0
      this.totalFailed = 0
    },
    async employeeImport() {
      this.importData = []
      // this.type = 1
      if (
        this.uploadFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const data = new FormData()
        data.append('file', this.uploadFile)
        await axios
          .post(`${this.upload}esanqua/upload_from_excel`, data)
          .then(res => {
            if (res.data.status_code === '00') {
              const arrResult = res.data.data
              console.log(arrResult)
              this.importData = arrResult
              this.type = 1
              this.uploadFile = null
              this.isImport = true
            } else {
              this.showMsgDialog('error', res.data.err_msg)
              this.importData = []
              this.isImport = false
            }
          })
          .catch(err => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
            this.importData = []
            this.isImport = false
          })
      } else {
        this.showMsgDialog('error', 'File type must be .xlsx', false)
        this.clear()
      }
    },
    saveVueImport() {
      this.loading = true
      const form = {
        // act: 'add',
        data: this.importData
      }
      axios
        .post(`${this.attendance}log/import`, form)
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            this.importData = []
            this.type = 2
            this.showMsgDialog('success', res.data.status_msg, false)
            for (let i = 0; i < res.data.result_import.length; i++) {
              const param = {
                nik: res.data.result_import[i].nik,
                attendance_time: res.data.result_import[i].attendance_time,
                msg: res.data.result_import[i].msg
              }
              this.importData.push(param)

              if (
                res.data.result_import[i].msg ===
                  'Data has been successfully saved' ||
                res.data.result_import[i].msg ===
                  'Data has been successfully updated'
              ) {
                this.totalSuccess = this.totalSuccess + 1
              } else {
                this.totalFailed = this.totalFailed + 1
              }
            }
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
            this.importData = []
          }
          this.isImport = false
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          this.remove()
        })
    },
    colorStatus(val) {
      if (val === '00') {
        return 'green'
      }
      if (val === '-99') {
        return 'red'
      }
      return 'black'
    },
    importStatus(val) {
      if (val === '00') {
        return 'Import Success'
      }
      if (val === '-99') {
        return 'Import Error'
      }
      return '-'
    },
    itemRowBackground(item) {
      if (item.status_code === '-99') {
        return 'red'
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
.red {
  background-color: red;
}
.arch-add-new-btn {
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
}
</style>
